<template>
  <v-navigation-drawer
    v-model="drawerVisible"
    app
    left
    :temporary="false"
    :permanent="true"
    :floating="true"
    :width="drawerVisible ? (mini ? 65 : 250) : 0"
    class="job-updates-feed"
    :style="drawerStyles"
  >
  <div class="drawer-content">
    <template>
      <div class="modern-drawer-header">
        <div class="header-controls">
          <button class="modern-button" @click="toggleMini">
            <span class="button-icon">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path d="M13 5l7 7-7 7M5 5l7 7-7 7" v-if="mini"/>
                <path d="M19 5l-7 7 7 7M11 5l-7 7 7 7" v-else/>
              </svg>
            </span>
            <span class="button-text" v-if="!mini">Collapse Sidebar</span>
          </button>

          <button v-if="!mini" class="modern-button" @click="handleRefresh" :class="{ 'refreshing': isRefreshing }">
            <span class="button-icon">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path d="M21.5 2v6h-6M2.5 22v-6h6M2 12c0-2.8 1.2-5.2 3.2-7C7 3.2 9.6 2 12.5 2M22 12c0 2.8-1.2 5.2-3.2 7-1.8 1.8-4.4 3-7.3 3"/>
              </svg>
            </span>
            <span class="button-text">Refresh Sources</span>
          </button>
        </div>

        <div class="metrics-container">
          <!-- Entities Metric -->
          <div class="metric-card" :class="{ 'mini-mode': mini }">
            <router-link
              :to="{ name: 'entities' }"
              class="metric-content entities-link"
            >
              <div class="metric-value">
                <span class="number">{{ totalEntityCount }}</span>
                <span v-if="!mini" class="label">Entities</span>
              </div>
            </router-link>
          </div>

          <!-- Sources Metric -->
          <div
            class="metric-card"
            :class="{ 'mini-mode': mini }"
            @click="handleSubmissionsIconClick"
            style="cursor: pointer"
          >
            <div class="metric-content">
              <div class="metric-value">
                <span class="number">{{ sortedJobs.length }}</span>
                <span v-if="!mini" class="label">Sources</span>
              </div>
            </div>
          </div>

          <!-- New Jobs Metric -->
          <div v-if="newJobsCount > 0"
              class="metric-card highlight"
              :class="{ 'mini-mode': mini }">
            <div class="metric-content">
              <div class="metric-value">
                <span class="number success">+{{ newJobsCount }}</span>
                <span v-if="!mini" class="label">New Jobs</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Empty State -->
        <v-list-item v-if="sortedJobs.length === 0 && !mini" class="empty-state">
          <v-list-item-content>
            <div class="empty-state-content">
              <div class="icon-container">
                <v-icon size="48" class="empty-icon">mdi-cloud-question</v-icon>
              </div>
              <div v-if="!mini" class="empty-message">
                <h3 class="empty-title">No Recent Sources</h3>
                <p class="empty-description">
                  Build:
                  <ul class="action-list">
                    <li>Ask a question</li>
                    <li>Upload files</li>
                    <li>Paste text</li>
                    <li>Paste YouTube links</li>
                  </ul>
                </p>
              </div>
              <template v-if="!this.user.is_pro">
                <v-btn
                  color="primary"
                  :loading="subscriptionLoading"
                  @click="upgradePlan('pro')"
                  class="mt-4 black--text"
                  small
                >
                  <v-icon small left class="black--text">mdi-star-three-points-outline</v-icon>
                  Try Pro
                </v-btn>
              </template>
            </div>
          </v-list-item-content>
        </v-list-item>
        <template v-else>
          <v-list-item
            v-for="(job) in sortedJobs"
            :key="`job-${job.type}-${job.id}`"
            @click="handleJobClick(job)"
            class="job-item"
          >
          <v-tooltip right :disabled="!mini" max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <div class="job-item-content" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-avatar :color="getJobColor(job)" size="48" rounded="sm" class="outer-avatar">
                      <v-avatar :color="getJobColor(job)" size="32" rounded="sm" class="inner-avatar">
                        <v-icon dark small>{{ getJobIcon(job) }}</v-icon>
                      </v-avatar>
                    </v-avatar>
                  </v-list-item-icon>

                  <v-list-item-content v-if="!mini">
                    <v-list-item-title class="job-title text-caption">
                      {{ getJobName(job) }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="job-subtitle text-caption">
                      {{ getJobTypeDisplay(job) }} • {{ getLastAttemptedAt(job) | formatRelativeDate }}
                    </v-list-item-subtitle>
                    <v-chip
                      v-if="job.sensitivity_level"
                      x-small
                      :color="getSensitivityColor(job.sensitivity_level)"
                      text-color="black"
                      class="mt-1"
                    >
                      <v-icon left x-small>{{ getSensitivityIcon(job.sensitivity_level) }}</v-icon>
                      {{ job.sensitivity_level }}
                    </v-chip>
                  </v-list-item-content>
                </div>
              </template>

              <!-- Tooltip Content for Mini Mode -->
              <v-card class="job-tooltip pa-3">
                <div class="d-flex align-center mb-2">
                  <v-avatar :color="getJobColor(job)" size="24" rounded="sm" class="mr-2">
                      <v-icon dark small>{{ getJobIcon(job) }}</v-icon>
                    </v-avatar>
                  <span class="font-weight-medium">{{ getJobName(job) }}</span>
                </div>

                <div class="tooltip-details">
                  <div class="detail-row">
                    <v-icon x-small color="grey">mdi-tag-outline</v-icon>
                    <span>{{ getJobTypeDisplay(job) }}</span>
                  </div>
                  <div class="detail-row">
                    <v-icon x-small color="grey">mdi-clock-outline</v-icon>
                    <span>{{ getLastAttemptedAt(job) | formatRelativeDate }}</span>
                  </div>
                  <div class="detail-row">
                    <v-icon x-small color="grey">mdi-information-outline</v-icon>
                    <span>{{ getJobStatus(job) }}</span>
                  </div>
                  <div v-if="job.sensitivity_level && user.is_pro_team" class="detail-row">
                    <v-icon x-small color="grey">mdi-lock</v-icon>
                    <span>{{ job.sensitivity_level }}</span>
                  </div>
                </div>
              </v-card>
            </v-tooltip>
            <v-list-item-action v-if="!mini">
              <div class="status-indicator d-flex align-center">
                <template v-if="job.type === 'Chat Message'">
                  <v-progress-circular
                    v-if="job.web_crawl_status === 'processing' || job.web_crawl_status === 'indexing'"
                    indeterminate
                    :color="getStatusColor(job.web_crawl_status)"
                    size="16"
                    width="2"
                    class="mr-1"
                  ></v-progress-circular>
                  <v-icon v-else :color="getStatusColor(job.web_crawl_status)" x-small class="mr-1">
                    {{ getStatusIcon(job.web_crawl_status) }}
                  </v-icon>
                  <v-progress-circular
                    v-if="job.index_build_status === 'processing' || job.index_build_status === 'indexing'"
                    indeterminate
                    :color="getStatusColor(job.index_build_status)"
                    size="16"
                    width="2"
                    class="mr-1"
                  ></v-progress-circular>
                  <v-icon v-else :color="getStatusColor(job.index_build_status)" x-small class="mr-1">
                    {{ getStatusIcon(job.index_build_status) }}
                  </v-icon>
                </template>
                <template v-else-if="job.type === 'Web Crawl'">
                  <v-progress-circular
                    v-if="job.status === 'processing' || job.status === 'indexing'"
                    indeterminate
                    :color="getStatusColor(job.status)"
                    size="16"
                    width="2"
                    class="mr-1"
                  ></v-progress-circular>
                  <v-icon v-else :color="getStatusColor(job.status)" x-small class="mr-1">
                    {{ getStatusIcon(job.status) }}
                  </v-icon>
                </template>
                <template v-else>
                  <v-progress-circular
                    v-if="job.status === 'processing' || job.status === 'indexing'"
                    indeterminate
                    :color="getStatusColor(job.status)"
                    size="16"
                    width="2"
                    class="mr-1"
                  ></v-progress-circular>
                  <v-icon v-else :color="getStatusColor(job.status)" x-small class="mr-1">
                    {{ getStatusIcon(job.status) }}
                  </v-icon>
                  <v-progress-circular
                    v-if="job.index_build_status === 'processing' || job.index_build_status === 'indexing'"
                    indeterminate
                    :color="getStatusColor(job.index_build_status)"
                    size="16"
                    width="2"
                    class="mr-1"
                  ></v-progress-circular>
                  <v-icon v-else :color="getStatusColor(job.index_build_status)" x-small class="mr-1">
                    {{ getStatusIcon(job.index_build_status) }}
                  </v-icon>
                </template>
              </div>
            </v-list-item-action>
          </v-list-item>
        </template>
    </div>
    </template>
  </div>
  </v-navigation-drawer>
</template>

<script>
import jobUpdatesApi from '@/websocket/api';
import { min } from 'lodash';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'JobUpdatesFeed',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: [String, Number],
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isRefreshing: false,
      mini: true,
      drawerVisible: this.visible,
      previousJobCount: 0,
      fileSubmissions: [],
      textSubmissions: [],
      webCrawls: [],
      youtubeTranscriptSubmissions: [],
      chatMessages: [],
      totalEntityCount: 0,
      projects: [],
      showSparks: false,
      isCollapsed: false,
      jobs: [],
      pollingInterval: null,
      isPolling: false,
      noProcessingJobsCount: 0,
      maxNoProcessingPolls: 5, // Poll every 15 seconds, stop after 75 seconds.
      listItemHeight: 75,
      topOffset: 60,
    }
  },
  computed: {
    ...mapState('websocket', ['tempMessage', 'showTempMessage']),
    ...mapState("auth", ["currentUser", "oauthLoading", "loading"]),
    ...mapState('websocket', ['subscriptionLoading']),
    drawerStyles() {
      return {
        transform: this.drawerVisible ? 'translateX(0)' : 'translateX(100%)',
        top: `${this.topOffset}px`,
        height: `calc(100% - ${this.topOffset}px)`,
      };
    },
    hasProcessingJobs() {
      return this.sortedJobs.some(job => {
        if (job.type === 'Chat Message') {
          return ['processing', 'indexing'].includes(job.web_crawl_status.toLowerCase()) ||
                 ['processing', 'indexing'].includes((job.index_build_status || '').toLowerCase());
        } else if (job.type === 'Web Crawl') {
          return ['processing', 'indexing'].includes(job.status.toLowerCase());
        } else {
          return ['processing', 'indexing'].includes(job.status.toLowerCase()) ||
                 ['processing', 'indexing'].includes((job.index_build_status || '').toLowerCase());
        }
      });
    },
    processingJobCount() {
      return this.sortedJobs.filter(job => {
        if (job.type === 'Chat Message') {
          return ['processing', 'indexing'].includes(job.web_crawl_status.toLowerCase()) ||
                 ['processing', 'indexing'].includes((job.index_build_status || '').toLowerCase());
        } else if (job.type === 'Web Crawl') {
          return ['processing', 'indexing'].includes(job.status.toLowerCase());
        } else {
          return ['processing', 'indexing'].includes(job.status.toLowerCase()) ||
                 ['processing', 'indexing'].includes((job.index_build_status || '').toLowerCase());
        }
      }).length;
    },
    newJobsCount() {
      return Math.max(0, this.jobCount - this.previousJobCount);
    },
    sortedJobs() {
      const allJobs = [
        ...this.fileSubmissions.map(fs => ({
          ...fs,
          type: 'File Submission',
          updated_at: fs.updated_at || fs.created_at
        })),
        ...this.textSubmissions.map(ts => ({
          ...ts,
          type: 'Text Submission',
          updated_at: ts.updated_at || ts.created_at
        })),
        ...this.youtubeTranscriptSubmissions.map(yts => ({
          ...yts,
          type: 'YouTube Transcript',
          updated_at: yts.updated_at || yts.created_at
        })),
        ...this.chatMessages.map(cm => ({
          ...cm,
          type: 'Chat Message',
          updated_at: cm.web_crawl_last_attempted_at || cm.index_build_last_attempted_at || cm.updated_at || cm.created_at,
          web_crawl_ids: cm.associated_web_crawls.map(wc => wc.id)
        })),
        ...this.webCrawls.map(wc => ({
          ...wc,
          type: 'Web Crawl',
          updated_at: wc.updated_at || wc.created_at
        }))
      ];

      // Sort all jobs by updated_at
      const sortedAllJobs = allJobs.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      // Group Web Crawls with their parent Chat Messages
      const groupedJobs = [];
      const webCrawlMap = new Map();

      // Create a map of Web Crawls by their ID
      this.webCrawls.forEach(wc => {
        webCrawlMap.set(wc.id, { ...wc, type: 'Web Crawl' });
      });

      sortedAllJobs.forEach(job => {
        if (job.type === 'Chat Message') {
          groupedJobs.push(job);
          if (job.associated_web_crawls && job.associated_web_crawls.length > 0) {
            job.associated_web_crawls.forEach(associatedWc => {
              if (webCrawlMap.has(associatedWc.id)) {
                const webCrawl = webCrawlMap.get(associatedWc.id);
                groupedJobs.push({
                  ...webCrawl,
                  status: associatedWc.status,
                  parent_id: job.id
                });
                webCrawlMap.delete(associatedWc.id);
              }
            });
          }
        } else if (job.type !== 'Web Crawl') {
          groupedJobs.push(job);
        }
      });

      // Add any remaining Web Crawls that weren't associated with a Chat Message
      webCrawlMap.forEach(webCrawl => {
        groupedJobs.push(webCrawl);
      });

      return groupedJobs;
    },
    jobCount() {
      return this.sortedJobs.length;
    }
  },
  mounted() {
    this.fetchJobs();
  },
  beforeDestroy() {
    this.stopPolling();
    this.previousJobCount = 0;
  },
  watch: {
    visible(newValue) {
      this.drawerVisible = newValue;
    },
    drawerVisible(newValue) {
      this.$emit('update:visible', newValue);
    },
    hasProcessingJobs(newValue) {
      this.$emit('processing-jobs-change', newValue);
    },
    processingJobCount(newValue) {
      this.$emit('processing-job-count-change', newValue);
    },
    projectId: {
      handler() {
        this.fetchJobs();
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject', 'showTempMessageFn','upgradePlan']),
    ...mapActions("auth", ["oauthLogin"]),
    handleSubmissionsIconClick() {
      this.$emit('handle-submissions-icon-click')
      this.settingsDialogOpen = false
    },
    async handleRefresh() {
      this.isRefreshing = true;
      await this.fetchJobs();
      setTimeout(() => {
        this.isRefreshing = false;
      }, 600);
    },
    toggleMini() {
      this.mini = !this.mini;
      this.$emit('mini-change', this.mini);
    },
    navigateToEmailLogin() {
      // Navigate to the login page
      this.$router.push({ name: 'BasicLogin' });
    },
    getSensitivityIcon(level) {
      switch(level) {
        case 'Internal':
          return 'mdi-lock-open-variant-outline';
        case 'Confidential':
          return 'mdi-lock';
        default:
          return 'mdi-eye-off';
      }
    },
    getSensitivityColor(level) {
      switch(level) {
        case 'Internal':
          return '#10B981';
        case 'Confidential':
          return '#F43F5E';
        default:
          return 'grey';
      }
    },
    shouldShowConnector(job, index) {
      return job.type === 'Chat Message' &&
             job.associated_web_crawls &&
             job.associated_web_crawls.length > 0;
    },
    getConnectorHeight(index) {
      let height = 0;
      let i = index + 1;
      while (i < this.sortedJobs.length && this.sortedJobs[i].type === 'Web Crawl') {
        height += this.listItemHeight;
        i++;
      }
      return `${height}px`;
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    getJobStatus(job) {
      if (job.type === 'Chat Message') {
        return `${job.web_crawl_status} / ${job.index_build_status || 'N/A'}`;
      } else if (job.type === 'Web Crawl') {
        return job.status || 'N/A';
      }
      return `${job.status} / ${job.index_build_status || 'N/A'}`;
    },
    getIndexBuildStatus(job) {
      if (job.type === 'Chat Message') {
        if (!job.thumbs_up) {
          return 'n/a';
        }
        return job.index_build_status || 'pending';
      }
      return job.index_build_status || 'n/a';
    },
    getJobColor(job) {
      switch(job.type) {
        case 'File Submission': {
          const imageExtensions = ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.tiff', '.tif'];
          const fileName = job.file_name ? job.file_name.toLowerCase() : '';
          const isImage = imageExtensions.some(ext => fileName.endsWith(ext));

          return isImage ? '#10B981' : '#F43F5E';
        }
        case 'Text Submission':
          return '#3B82F6'; // Vibrant blue
        case 'Web Crawl':
          return '#004bfb'; // Modern indigo
        case 'YouTube Transcript':
          return '#F43F5E'; // Modern rose/red
        case 'Chat Message':
          return '#004bfb'; // Modern blue
        default:
          return '#64748B'; // Modern slate
      }
    },
    getRandomColor() {
      const colors = [
        'indigo darken-2',
        'deep-purple darken-2',
        'blue darken-3',
        'teal darken-2',
        'green darken-3',
        'orange darken-3',
        'deep-orange darken-2',
        'brown darken-2',
        'blue-grey darken-2',
        'red darken-2',
        'pink darken-2'
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    getStatusColor(status) {
      switch(status.toLowerCase()) {
        case 'pending': return 'grey';
        case 'processing': return 'primary';
        case 'completed': return 'success';
        case 'failed': return 'error';
        case 'success': return 'success';
        case 'indexing': return 'info';
        case 'n/a': return 'grey';
        default: return 'grey';
      }
    },
    getStatusIcon(status) {
      switch(status.toLowerCase()) {
        case 'pending': return 'mdi-clock-outline';
        case 'processing': return 'mdi-cog';
        case 'completed': return 'mdi-check-circle-outline';
        case 'failed': return 'mdi-alert-circle-outline';
        case 'success': return 'mdi-check-circle-outline';
        case 'indexing': return 'mdi-database-sync';
        case 'n/a': return 'mdi-minus-circle-outline';
        default: return 'mdi-help-circle-outline';
      }
    },
    handleJobClick(job) {
      let type;
      switch(job.type) {
        case 'File Submission':
          type = 'FileSubmission';
          break;
        case 'Text Submission':
          type = 'TextSubmission';
          break;
        case 'Web Crawl':
          type = 'Website';
          break;
        case 'YouTube Transcript':
          type = 'YouTubeTranscriptSubmission';
          break;
        case 'Chat Message':
          type = 'ChatMessage';
          break;
        default:
          type = 'Unknown';
      }

      this.setActiveObject({
        type: type,
        id: job.id,
      });
    },
    // Fetches the latest job updates
    async fetchJobs() {
      try {
        const { data } = await jobUpdatesApi.getJobUpdates({
          limit: 100,
          time_range: 720,
          project_id: this.projectId
        });
        this.fileSubmissions = data.file_submissions;
        this.textSubmissions = data.text_submissions;
        this.webCrawls = data.web_crawls;
        this.youtubeTranscriptSubmissions = data.youtube_transcript_submissions;
        this.chatMessages = data.chat_messages;

        this.previousJobCount = this.jobCount;

        if (this.totalEntityCount !== data.total_entity_count) {
          this.previousEntityCount = this.totalEntityCount;
          this.totalEntityCount = data.total_entity_count;
          this.showSparks = true;
          setTimeout(() => {
            this.showSparks = false;
          }, 5000);
        }
        // Check if we should continue polling
        this.$nextTick(() => {
          this.$emit('update-job-count', this.jobCount);

          if (!this.hasProcessingJobs) {
            this.noProcessingJobsCount++;
            if (this.noProcessingJobsCount > this.maxNoProcessingPolls) {
              this.stopPolling();
              this.$emit('processing-jobs-change', false);
            }
          } else {
            this.noProcessingJobsCount = 0;
            this.$emit('processing-jobs-change', true);
          }
        });
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
      }
    },
    startPolling() {
      if (!this.isPolling) {
        this.isPolling = true;
        this.noProcessingJobsCount = 0;
        this.fetchJobs(); // Fetch immediately when starting
        this.pollingInterval = setInterval(() => {
          this.fetchJobs();
        }, 15000); // Poll every 15 seconds
      }
    },
    stopPolling() {
      if (this.isPolling) {
        clearInterval(this.pollingInterval);
        this.isPolling = false;
        this.noProcessingJobsCount = 0;
      }
    },
    onNewJobSubmitted() {
      this.startPolling();
    },
    formatTime(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
    getJobName(job) {
      switch(job.type) {
        case 'File Submission':
          return job.file_name;
        case 'Text Submission':
          return job.name ? job.name.substring(0, 20) + '...' : 'N/A';
        case 'Web Crawl':
          return job.name || job.url;
        case 'YouTube Transcript':
          return job.video_title || `YouTube ${job.id}`;
        case 'Chat Message':
          return job.user_message.substring(0, 50) + (job.user_message.length > 20 ? '...' : '');
        default:
          return 'Unknown Job';
      }
    },
    getJobIcon(job) {
      switch(job.type) {
        case 'File Submission': {
          const imageExtensions = ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.tiff', '.tif'];
          const fileName = job.file_name ? job.file_name.toLowerCase() : '';
          const isImage = imageExtensions.some(ext => fileName.endsWith(ext));

          return isImage ? 'mdi-file-image-outline' : 'mdi-file-document-outline';
        }
        case 'Text Submission':
          return 'mdi-text-box-outline';
        case 'Web Crawl':
          return 'mdi-web';
        case 'YouTube Transcript':
          return 'mdi-youtube';
        case 'Chat Message':
          return 'mdi-web';
        default:
          return 'mdi-help-circle-outline';
      }
    },
    getJobTypeDisplay(job) {
      if (job.type === 'Web Crawl') {
        const url = new URL(job.url);
        const siteName = url.hostname.replace('www.', '');
        return `${siteName}`;
      }
      // Return only the first word of the job type
      return job.type.split(' ')[0];
    },
    getLastAttemptedAt(job) {
      if (job.type === 'Chat Message') {
        return job.web_crawl_last_attempted_at || job.index_build_last_attempted_at || new Date().toISOString();
      }
      return job.updated_at;
    },
  }
}
</script>

<style scoped>
.job-updates-feed {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  transition: all 0.3s ease;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .v-navigation-drawer {
    display: none !important; /* Use !important to override Vuetify defaults */
  }

  /* Adjust main content when drawer is hidden */
  .v-main {
    padding-left: 0 !important;
  }
}

.theme--dark.v-navigation-drawer.job-updates-feed {
  background-color: rgba(30, 34, 39, 0.95) !important;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid hsla(0, 0%, 0%, .05);
  box-shadow: 0 0 10px hsla(0, 0%, 0%, .03);
}

.theme--dark .drawer-content {
  border: 1px solid hsla(0, 0%, 100%, .05);
  box-shadow: 0 0 10px hsla(0, 0%, 100%, .02);
}

.drawer-scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
}

.feed-content {
  max-height: 100%;
  overflow-y: auto;
}

.feed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-top: 10px;
}

.feed-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.feed-title {
  font-size: 18px;
  font-weight: bold;
}


.feed-content {
  flex-grow: 1;
  overflow-y: auto;
}

.position-relative {
  position: relative;
}

.modern-drawer-header {
  padding: 0px;
}

.header-controls {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 10px 10px 0px 10px;
}

.toggle-btn, .refresh-btn {
  opacity: 0.7;
  transition: all 0.3s ease;
}

.toggle-btn:hover, .refresh-btn:hover {
  opacity: 1;
  transform: scale(1.05);
}

.refresh-icon {
  transition: transform 0.3s ease;
}

.refresh-btn:hover .refresh-icon {
  transform: rotate(180deg);
}

.metrics-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
}

.metric-card {
  background: linear-gradient(145deg, rgba(96, 165, 250, 0.05), rgba(147, 197, 253, 0.1));
  border: 1px solid rgba(96, 165, 250, 0.15);
  border-radius: 12px;
  padding: 12px;
  transition: all 0.3s ease;
}

.metric-card:hover {
  transform: translateX(4px);
  background: linear-gradient(145deg, rgba(96, 165, 250, 0.1), rgba(147, 197, 253, 0.15));
  border-color: rgba(96, 165, 250, 0.25);
}

.metric-card.highlight {
  background: linear-gradient(145deg, rgba(34, 197, 94, 0.05), rgba(74, 222, 128, 0.1));
  border-color: rgba(34, 197, 94, 0.2);
}

.entities-link {
  text-decoration: none;
  color: inherit;
}

.metric-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.metric-value {
  display: flex;
  align-items: center;
  gap: 12px;
}

.number {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--v-error-base);
  min-width: 32px;
  text-align: center;
}

.number.success {
  color: var(--v-success-base);
}

.label {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}

.metric-details {
  display: flex;
  align-items: center;
  gap: 6px;
}

.info-icon {
  opacity: 0.5;
  transition: opacity 0.2s ease;
  cursor: help;
}

.info-icon:hover {
  opacity: 1;
}

/* Empty State Styling */
.empty-state {
  padding: 24px 0px;
}

.empty-state-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.icon-container {
  background: rgba(96, 165, 250, 0.1);
  border-radius: 50%;
  padding: 16px;
  margin-bottom: 8px;
}


.empty-title {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 8px;
}

.empty-description {
  font-size: 0.875rem;
  line-height: 1.5;
}

.action-list {
  list-style: none;
  padding: 0;
  margin: 12px 0;
}

.action-list li {
  margin: 8px 0;
  padding-left: 24px;
  position: relative;
}

.action-list li::before {
  content: '→';
  position: absolute;
  left: 0;
  color: var(--v-primary-base);
}

.auth-button {
  margin-top: 8px !important;
  width: 100%;
  max-width: 250px;
}

/* Mini Mode Adjustments */
.mini-mode .metric-content {
  justify-content: center;
}

.mini-mode .number {
  font-size: 1rem;
}

.metric-tooltip {
  max-width: 280px;
  padding: 12px;
}

.metric-tooltip h4 {
  margin-bottom: 8px;
  color: var(--v-primary-base);
}

.metric-tooltip ul {
  margin: 8px 0 0 16px;
  padding: 0;
}

.metric-tooltip li {
  margin: 4px 0;
  font-size: 0.8rem;
}

.job-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 0px 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  border: 1px solid transparent;
}

.job-item:hover {
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
}

.job-item-content {
  width: 100%;
  display: flex;
  align-items: center;
}

.job-type {
  font-weight: bold;
  width: 100%;
  margin-bottom: 5px;
  color: #004bfb;
}

.job-name {
  flex: 1;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-indicator {
  display: flex;
  align-items: center;
  margin-left: -15px;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 4px;
}

.status-dot.pending { background-color: #FFA000; }
.status-dot.processing { background-color: #2196F3; }
.status-dot.completed { background-color: #4CAF50; }
.status-dot.failed { background-color: #F44336; }

.job-time {
  font-size: 10px;
  color: #BDBDBD;
  width: 100%;
  text-align: right;
  margin-top: 3px;
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s;
}

.chat-hint.visible {
  opacity: 1;
}
.job-item:hover .chat-hint {
  opacity: 1;
  animation: pulse 1.5s infinite;
}
.job-item:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% { transform: translateY(-50%) scale(1); }
  50% { transform: translateY(-50%) scale(1.1); }
  100% { transform: translateY(-50%) scale(1); }
}

/* Scrollbar styles */
.feed-content::-webkit-scrollbar {
  width: 6px;
}

.message-text {
  padding: 5px;
}

.job-tooltip {
  backdrop-filter: blur(10px);
}

.tooltip-details {
  margin-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 8px;
}

.detail-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  font-size: 0.85rem;
}

.entity-info-tooltip {
  max-width: 300px;
}

/* Transitions */
.v-navigation-drawer {
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Custom Scrollbar */
.drawer-scrollable-content::-webkit-scrollbar {
  width: 4px;
}

.drawer-scrollable-content::-webkit-scrollbar-track {
  background: transparent;
}

.drawer-scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.drawer-scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

.modern-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid rgba(96, 165, 250, 0.2);
  background: rgba(96, 165, 250, 0.1);
  color: #004bfb;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.875rem;
}

.modern-button:hover {
  background: rgba(96, 165, 250, 0.2);
  transform: translateY(-1px);
}

.button-icon {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.refreshing .button-icon {
  transform: rotate(180deg);
}

.button-text {
  font-size: 0.875rem;
  font-weight: 500;
}

/* Dark theme support */
.theme--dark .modern-button {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.8);
}

.theme--dark .modern-button:hover {
  background: rgba(255, 255, 255, 0.15);
}
</style>
