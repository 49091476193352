<template>
  <div class="empty-state">
    <!-- Neural Network Background -->
    <svg class="neural-bg" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
      <!-- Grid Points -->
      <g class="grid-points">
        <template v-for="x in 6">
          <template v-for="y in 6">
            <circle
              :key="`point-${x}-${y}`"
              :cx="x * 60"
              :cy="y * 60"
              r="2"
              class="grid-point"
              :style="{ animationDelay: `${(x + y) * 0.2}s` }"
            />
          </template>
        </template>
      </g>

      <!-- Connecting Lines -->
      <g class="grid-lines">
        <template v-for="x in 6">
          <template v-for="y in 6">
            <template v-if="x < 6">
              <line
                :key="`h-line-${x}-${y}`"
                :x1="x * 60"
                :y1="y * 60"
                :x2="(x + 1) * 60"
                :y2="y * 60"
                class="grid-line"
                :style="{ animationDelay: `${(x + y) * 0.2}s` }"
              />
            </template>
            <template v-if="y < 6">
              <line
                :key="`v-line-${x}-${y}`"
                :x1="x * 60"
                :y1="y * 60"
                :x2="x * 60"
                :y2="(y + 1) * 60"
                class="grid-line"
                :style="{ animationDelay: `${(x + y) * 0.2}s` }"
              />
            </template>
            <template v-if="x < 6 && y < 6">
              <line
                :key="`d-line-${x}-${y}`"
                :x1="x * 60"
                :y1="y * 60"
                :x2="(x + 1) * 60"
                :y2="(y + 1) * 60"
                class="grid-line diagonal"
                :style="{ animationDelay: `${(x + y) * 0.3}s` }"
              />
            </template>
          </template>
        </template>
      </g>

      <!-- Occasional Data Pulse -->
      <g class="data-pulses">
        <template v-for="n in 3">
          <circle
            :key="`pulse-${n}`"
            :cx="200"
            :cy="200"
            r="3"
            class="data-pulse"
            :style="{ animationDelay: `${n * 2}s` }"
          />
        </template>
      </g>
    </svg>

    <!-- Main Content -->
    <h2 class="title">Deep Research</h2>
    <p class="description">
     Zero-click fact-checks
    </p>
  </div>
</template>

<script>
export default {
  name: 'EmptyState'
}
</script>

<style scoped>
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 800px;
  padding: 2rem;
  position: relative;
}

.neural-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.grid-point {
  fill: #2563eb;
  opacity: 0.4;
  animation: pointPulse 6s ease-in-out infinite;
}

.grid-line {
  stroke: #2563eb;
  stroke-width: 1;
  opacity: 0.15;
  animation: lineFlow 6s ease-in-out infinite;
}

.diagonal {
  opacity: 0.08;
  stroke-dasharray: 6;
  animation: diagonalFlow 8s linear infinite;
}

.data-pulse {
  fill: #2563eb;
  opacity: 0;
  animation: dataPulse 4s ease-out infinite;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  background: linear-gradient(90deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
  z-index: 1;
}

.description {
  color: #64748b;
  text-align: center;
  max-width: 32rem;
  line-height: 1.6;
  position: relative;
  z-index: 1;
}

@keyframes pointPulse {
  0%, 100% { opacity: 0.3; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(1.2); }
}

@keyframes lineFlow {
  0%, 100% { opacity: 0.1; }
  50% { opacity: 0.2; }
}

@keyframes diagonalFlow {
  0% { stroke-dashoffset: 0; }
  100% { stroke-dashoffset: 24; }
}

@keyframes dataPulse {
  0% {
    opacity: 0.4;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

/* Dark theme adjustments */
.theme--dark .empty-state {
  background: #0f172a;
}

.theme--dark .description {
  color: #94a3b8;
}
</style>
