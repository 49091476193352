<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      :class="classes"
      viewBox="0 0 24 24"
    >
      <path
        d="M3 12c0-4.97 4.03-9 9-9s9 4.03 9 9c0 1.76-.5 3.4-1.38 4.79-.38.61-.38 1.37 0 1.98l1.25 2.02c.27.44-.15.97-.66.84l-3.47-.87a2 2 0 0 0-1.32.12A8.95 8.95 0 0 1 12 21c-4.97 0-9-4.03-9-9z"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8.5v7M8.5 12h7"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  </template>

  <script>
  export default {
    name: 'NewChatIcon',
    props: {
      size: {
        type: [Number, String],
        default: 24
      },
      classes: {
        type: [String, Object, Array],
        default: ''
      }
    }
  }
  </script>
