<template>
  <div class="search-results-visualization" :class="{ 'collapsed': isCollapsed }">
    <div class="search-status-bar" @click="toggleCollapse">
      <div class="status-wrapper">
        <div class="status-icon">
          <div class="neural-animation" v-if="!finalAnswerReceived">
            <div class="neural-pulse"></div>
            <div class="neural-ring"></div>
          </div>
          <i v-else class="mdi mdi-check-circle completion-icon"></i>
        </div>
        <div class="status-content">
          <div class="status-text">{{ statusMessage }}</div>
          <div class="status-progress" v-if="!finalAnswerReceived">
            <div class="progress-bar"></div>
          </div>
        </div>
      </div>
      <div class="meta-info">
        <div class="results-counter" v-if="!isCollapsed">
          <span class="counter-number">{{ processedResults.length }}</span>
          <span class="counter-separator">/</span>
          <span class="counter-total">{{ results.length }}</span>
        </div>
        <div class="collapse-icon">
          <i :class="isCollapsed ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'"></i>
        </div>
      </div>
    </div>

    <transition-group name="result-transition" tag="div" class="results-container" v-if="!isCollapsed">
      <div v-for="(result) in processedResults" :key="result.id" class="result-item">
        <div class="result-connector">
          <div class="connector-line"></div>
          <div class="connector-dot"></div>
        </div>
        <div class="result-content">
          <div class="result-header">
            <!-- Add image if available -->
            <div class="result-image">
              <img
                v-if="result.thumbnail || result.image"
                :src="result.thumbnail || result.image"
                :alt="result.title"
                @error="handleImageError"
              >
              <svg
                v-else
                width="120"
                height="90"
                viewBox="0 0 120 90"
                class="placeholder-svg"
              >
                <!-- Gradient and pattern definitions -->
                <defs>
                  <linearGradient id="placeholder-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style="stop-color:#f3f4f6"/>
                    <stop offset="100%" style="stop-color:#e5e7eb"/>
                  </linearGradient>
                  <pattern id="placeholder-pattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                    <circle cx="5" cy="5" r="1" fill="#d1d5db" opacity="0.3"/>
                  </pattern>
                </defs>

                <!-- Background with gradient -->
                <rect
                  width="120"
                  height="90"
                  fill="url(#placeholder-grad)"
                  rx="4"
                  ry="4"
                />

                <!-- Pattern overlay -->
                <rect
                  width="120"
                  height="90"
                  fill="url(#placeholder-pattern)"
                  rx="4"
                  ry="4"
                />

                <!-- Modern icon -->
                <g transform="translate(45,25)">
                  <path
                    d="M0 25 L10 15 L20 22 L30 5 L40 25"
                    stroke="#9ca3af"
                    stroke-width="2"
                    fill="none"
                  />
                  <circle
                    cx="25"
                    cy="10"
                    r="4"
                    fill="#9ca3af"
                  />
                </g>

                <!-- Text background for better readability -->
                <rect
                  x="10"
                  y="60"
                  width="100"
                  height="20"
                  fill="rgba(255,255,255,0.9)"
                  rx="2"
                />

                <!-- Truncated title text -->
                <text
                  x="60"
                  y="74"
                  text-anchor="middle"
                  fill="#4b5563"
                  font-size="10"
                  font-family="system-ui, -apple-system, sans-serif"
                >{{ truncateTitle(result.title) }}</text>
              </svg>
            </div>
            <div class="result-info">
              <div class="result-title" v-if="result.title">{{ result.title }}</div>
              <!-- Add date -->
              <div class="result-date" v-if="result.date">
                {{ formatDate(result.date) }}
              </div>
              <a :href="result.url" target="_blank" rel="noopener noreferrer" class="result-url">
                {{ result.url }}
              </a>
            </div>
          </div>
          <div class="result-description" v-if="result.description">
            {{ result.description }}
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'SearchResultsAnimation',
  props: {
    results: {
      type: Array,
      required: true
    },
    isSearching: {
      type: Boolean,
      default: false
    },
    finalAnswerReceived: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSearchingLocal: false,
      processedResults: [],
      statusMessages: [
        'Initializing neural pathways...',
        'Analyzing semantic patterns...',
        'Synthesizing knowledge graph...',
        'Optimizing relevance matrix...',
        'Correlating data streams...',
        'Mapping conceptual networks...',
        'Aligning information vectors...',
        'Calibrating response models...'
      ],
      completedMessage: 'Search complete • Tap to expand',
      currentStatusIndex: 0,
      isCollapsed: false
    }
  },
  computed: {
    statusMessage() {
      return this.finalAnswerReceived ? this.completedMessage : this.statusMessages[this.currentStatusIndex]
    }
  },
  methods: {
    truncateTitle(title) {
      return title ? (title.length > 15 ? title.substring(0, 15) + '...' : title) : 'No Title';
    },
    handleImageError(e) {
      console.log('Image failed to load:', e.target.src);  // For debugging
      e.target.style.display = 'none';
      e.target.parentElement.style.display = 'none';  // Hide the container too
    },
    formatDate(dateStr) {
      if (!dateStr) return 'No date available';

      // Handle ISO dates
      if (dateStr.includes('T')) {
        return new Date(dateStr).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }

      // For dates already in readable format, return as is
      return dateStr;
    },
    animateResults(results) {
      results.forEach((result, index) => {
        setTimeout(() => {
          this.processedResults.push(result)
          this.currentStatusIndex = (index % this.statusMessages.length)
        }, index * 800)
      })
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
      this.$emit('collapsed', this.isCollapsed)
    }
  },
  watch: {
    results: {
      immediate: true,
      handler(newResults) {
        if (newResults.length === 0) return
        this.isSearchingLocal = this.isSearching
        this.processedResults = []
        if (newResults.length > 0) {
          this.animateResults(newResults)
        }
      }
    },
    finalAnswerReceived: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.isSearchingLocal = false
          this.$emit('update:finalAnswer', true)
          setTimeout(() => {
            this.isCollapsed = true
            this.$emit('collapsed', true)
          }, 300)
        }
      }
    }
  }
}
</script>

<style scoped>
.search-results-visualization {
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.search-results-visualization.collapsed {
  padding: 0.5rem;
}

.search-status-bar {
  background:  rgba(167, 139, 250, 0.2);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(167, 139, 250, 0.15);
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.05),
    0 0 1px rgba(167, 139, 250, 0.2);
  padding: 1rem 1.5rem;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.status-wrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
}

.neural-animation {
  position: relative;
  width: 24px;
  height: 24px;
}

.neural-pulse {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(99, 102, 241, 0.3);
  animation: neuralPulse 2s infinite;
}

.neural-ring {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #6366f1;
  border-radius: 50%;
  animation: neuralRing 2s infinite;
}

.status-content {
  flex-grow: 1;
}

.status-text {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  background: linear-gradient(90deg, #fff, #a5b4fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
}

.status-progress {
  height: 2px;
  background: rgba(99, 102, 241, 0.2);
  border-radius: 2px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #6366f1, #a5b4fc);
  animation: progress 2s infinite;
  border-radius: 2px;
}

.meta-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.results-counter {
  font-family: 'SF Mono', Monaco, monospace;
  background: rgba(99, 102, 241, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  border: 1px solid rgba(99, 102, 241, 0.2);
}

.counter-number {
  color: #6366f1;
  font-weight: 600;
}

.counter-separator {
  margin: 0 0.25rem;
  opacity: 0.5;
}

.counter-total {
  opacity: 0.7;
}

.completion-icon {
  color: #10B981;
  font-size: 1.5rem;
  animation: completionPop 0.3s ease-out;
}

.results-container {
  position: relative;
  transition: all 0.3s ease;
}

.result-item {
  display: flex;
  align-items: flex-start;
  margin: 1rem 0;
  position: relative;
}

.result-connector {
  width: 24px;
  margin-right: 1rem;
  position: relative;
}

.connector-line {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(99, 102, 241, 0.2);
  transform: translateX(-50%);
}

.connector-dot {
  width: 8px;
  height: 8px;
  background: #6366f1;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 12px rgba(99, 102, 241, 0.3);
  animation: connectorPulse 2s infinite;
}

.result-content {
  flex-grow: 1;
  background: linear-gradient(
    135deg,
    rgba(99, 102, 241, 0.06) 0%,
    rgba(99, 102, 241, 0.1) 100%
  );
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(99, 102, 241, 0.15);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.05),
    0 0 1px rgba(99, 102, 241, 0.2);
  backdrop-filter: blur(8px);
}

.result-content:hover {
  transform: translateY(-2px);
  box-shadow:
    0 8px 16px -4px rgba(0, 0, 0, 0.1),
    0 4px 8px -2px rgba(99, 102, 241, 0.15);
  border-color: rgba(99, 102, 241, 0.25);
  background: linear-gradient(
    135deg,
    rgba(99, 102, 241, 0.08) 0%,
    rgba(99, 102, 241, 0.14) 100%
  );
}

.result-url {
  font-size: 0.9rem;
  color: #6366f1;
  text-decoration: none;
  display: inline-block;
  word-break: break-all;
  padding: 0.25rem 0;
  position: relative;
  transition: all 0.2s ease;
}

.result-name {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 0.5rem;
}

@keyframes neuralPulse {
  0% { transform: scale(0.8); opacity: 0.8; }
  50% { transform: scale(1.2); opacity: 0.2; }
  100% { transform: scale(0.8); opacity: 0.8; }
}

@keyframes neuralRing {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

@keyframes progress {
  0% { transform: translateX(-100%); }
  50% { transform: translateX(0); }
  100% { transform: translateX(100%); }
}

@keyframes completionPop {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

@keyframes connectorPulse {
  0% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
  50% { transform: translate(-50%, -50%) scale(1.5); opacity: 0.5; }
  100% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
}

.result-transition-enter-active {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.result-transition-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.result-transition-enter-from {
  opacity: 0;
  transform: translateY(20px) scale(0.95);
}

.result-transition-leave-to {
  opacity: 0;
  transform: translateY(-20px) scale(0.95);
}

.result-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.result-title {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.4;
}

.result-description {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  margin-top: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Update result-url styles */
.result-url {
  font-size: 0.85rem;
  color: #6366f1;
  opacity: 0.8;
}
.result-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.75rem;
}

.result-image {
  flex-shrink: 0;
  width: 120px;
  height: 90px;
  border-radius: 6px;
  overflow: hidden;
  background: rgba(99, 102, 241, 0.1);
}

.result-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-svg {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.result-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.result-date {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.5);
}

/* Update existing styles */
.result-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.result-title {
  font-weight: 500;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
